import { Component, Host, OnInit, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Colmodel, GridFIlterType } from 'src/app/modules/commongrid/colmodel.model';
import { CommongridComponent } from 'src/app/modules/commongrid/commongrid.component';

import { DataService, UtilsService } from 'src/app/modules/shared/services';
import { AuthService } from '../auth/services/auth.service';

import { DialogService } from 'primeng/dynamicdialog';
import { PatientRegisterService } from '../patient-registration/services/patient-register.service';
import { RppPatientService } from './services/rpp-patient.service';
import { IpdRegistrationService } from '../ipd-registration/services/ipd-registration.service';

@Component({
  selector: 'app-rpp-patient-list',
  templateUrl: './rpp-patient-list.component.html',
  styleUrls: ['./rpp-patient-list.component.scss']
})
export class RppPatientListComponent implements OnInit {
  toDisplay = false;
  globalPayLoad;
  cols: Colmodel[] = [];
  currentPage = 1;
  disableSearch = true;
  disableColumn
  dataSource
  patinetname
  gridData
  public contactNumber: any = '';
  @ViewChild(CommongridComponent) dataGrid: CommongridComponent;
  @ViewChild('actionTemplate', {static: true}) actionTemplate;
  @ViewChild('statuTemplate', {static: true}) statuTemplate;
  @ViewChild('payTemplate', {static: true}) payTemplate;

  copyDataShow : boolean= false;
  refId : any;
  copy : boolean = false;

  constructor(public dataService: DataService,
    private rppPatientService : RppPatientService,
              public authService: AuthService,
              public route: Router,
              public utilsService: UtilsService,
              private ipdRegService: IpdRegistrationService,
              private patientRegisterService: PatientRegisterService) {
      }

  ngOnInit(): void {
    if(this.dataService.userDetails.name == 'Receptionist'){
      this.disableColumn = true;
    }
    else{
      this.disableColumn = false;
    }
    this.cols = [
      new Colmodel('serial_num', 'Sr. No', false, true, false, { filtertype: GridFIlterType.INPUT }),
      new Colmodel('patient_ref_id', 'Patient Id', false, true, false, { filtertype: GridFIlterType.INPUT }),
      new Colmodel('patient_id', 'Id', false, true, true, {filtertype: GridFIlterType.INPUT}),
      new Colmodel('patient_name', 'Patient Name', false, true, false, {filtertype: GridFIlterType.INPUT}),
      new Colmodel('mobile_number', 'Mobile Number', false, true, false, {filtertype: GridFIlterType.INPUT}),
      new Colmodel('package_name', 'Plan Name', false, true, false, {filtertype: GridFIlterType.INPUT}),
      new Colmodel('service_details', 'Service Assigned', false, true, false, {filtertype: GridFIlterType.INPUT}),
       new Colmodel('payment_type_name', 'Payment Status', false, true, false, {filtertype: GridFIlterType.INPUT, formatterTemplateRef: this.payTemplate}),
      new Colmodel('status', 'Status', false, true, false, {filtertype: GridFIlterType.INPUT, formatterTemplateRef: this.statuTemplate}),
      new Colmodel('action', 'Actions', false, false, this.disableColumn, {filtertype: GridFIlterType.NONE, formatterTemplateRef: this.actionTemplate}),
    ];
    
    this.getPatientGridData()

    this.ipdRegService.getredIds.subscribe(res =>{
      if(res){
        this.copyDataShow = true;
        this.refId = res;
        // this.relodewendows()
      }
     })

     setTimeout(() => {
      console.log("setTimeoutsetTimeoutsetTimeoutsetTimeoutsetTimeout")
      this.copyDataShow = false;
    }, 8000);

  }
  toggleData(){
   
  }

  relodewendows(){
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  }


  dataCopy(){
    this.copy = true
  }



  getPatientGridData() {
    const tempPayload = {
      page_size: this.dataGrid && this.dataGrid.rows ? this.dataGrid.rows : 50,
      current_page: this.currentPage,
    };
    this.rppPatientService
      .getPatientRPPGridDetails(tempPayload)
      .subscribe((res: any) => {
        this.dataGrid.data = res.data;
        this.dataGrid.totalRecords = res.count;
      });
  }

  keyPressNumbers(event){
    var charCode = (event.which) ? event.which : event.keyCode;
    this.disableSearch =  this.contactNumber.length >= 9 ? false : true;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  onFocusOutEvent(event){
    this.disableSearch =  this.contactNumber.length >= 10 ? false : true;
    if (this.disableSearch) {
      this.getPatientGridData()
    }
  }

  searchWithPhoneNumber(){
    if(this.contactNumber == ""){
      this.getPatientGridData()
    }
    let payload =
    {
      registration_source : 2,
      search_keyword : this.contactNumber
    }
    this.patientRegisterService.findbyMobileNumber(payload).subscribe((res: any)=>{

      res.data.forEach((data: any, index) => {
        let srnumbers = index + 1
        data.serial_num = srnumbers;
      });

      this.dataGrid.data = res.data;
      this.dataGrid.totalRecords = res.count;
    })
  }

  editPatient(gridData) {
    const navigationExtras: NavigationExtras = {
      state: { editData: JSON.stringify(gridData) },
    };    
    this.route.navigate([`/rpp-assignment/assign-rpp/${gridData.patient_id}/${gridData.patient_rpp_id}`], navigationExtras)
  }
  redirectregisterpage(gridData){
    const navigationExtras: NavigationExtras = {
      state: { editData: JSON.stringify(gridData) },
    };    
    
    this.route.navigate([`ipd-registration`], navigationExtras)
  }
  onCustomFilter(event) {
    this.dataGrid.totalRecords = event.data.length;
  }

  onPageChangeEvent(event) {
    this.currentPage = event.first == 0 ? 1 : (event.first/event.rows) + 1;
    this.dataGrid.rows = event.rows;
    // const payload = {filters: event.filters};
    const payload = {limit: true};
    this.getPatientGridData();
  }


  ngOnDestroy(){
    this.copyDataShow = false
  }


}
