import { environment } from './../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BasicRegistrationService {

  private redId$ = new BehaviorSubject<any>('');
  getredId = this.redId$.asObservable();

  constructor(private http: HttpClient) { }

  setRefId(id):void {
    this.redId$.next(id);
  }

  getRoleslist() {
    return this.http.get(`${environment.admin_prefix_url}/role/getDoctorRoles`)
  }
  getUserbyRole(payload) {
    return this.http.post(`${environment.admin_prefix_url}/user/getDoctorsByRole`, payload)
  }

  getTimeSlot(payload) {
    return this.http.post(`${environment.appointment_url}/patient/getTimeSlots`, payload);
  }

  getOpdAmountPayable(payload) {
    return this.http.get(`${environment.admin_prefix_url}/servicemaster/getAllServices?role_id=${payload}`);
  }



  addBasicRegistration(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/basicRegistration`, payload);
  }

  opdRegistration(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/opd-registration `, payload);
  }

  getInvoice(payload){
    return this.http.post(`${environment.patient_registration_prefix_url}/billing/printInvoice`, payload);
  }

  findbyMobileNumber(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/searchByMobile`, payload)
  }

  getPatientDetailsByMobile(phone:string) {
    return this.http.get(`${environment.patient_registration_prefix_url}/patient/demography?mobile_number=${phone}`)
  }


  // responsefindbyMobileNumber(payload) {
  //   return this.http.post(`${environment.patient_registration_prefix_url}/patient/responesearchByMobile`, payload)
  // }


  updateMobileNumber(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/updateMobileNumber`, payload)
  }

  cancelPaymentLink(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/expirePaymentLink`, payload)
  }

  updatePatientDetails(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/updateBasicRegistration`, payload)
  }

  regernatePaymentLink(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/payment/regenratePaymentLink`, payload);
  }

  getCountryMaster() {
    return this.http.get(`${environment.admin_prefix_url}/location/countries`)
  }


  //DropDown Apis

  getGender(){
    return this.http.get(`${environment.patient_registration_prefix_url}/master/getPatientFormDetails?field_name=gender&form_name=patient_details`)
  }

  getPatientRegistrationHistory(patientId){
    return this.http.get(`${environment.patient_registration_prefix_url}/patient/history/${patientId}`)
  }

  updateOPd(paylaod:any, appointmentId:string) {
    return this.http.put(`${environment.patient_registration_prefix_url}/patient/opd-registration/${appointmentId}`, paylaod);
  }



  //AI Api

  getAiResult(payload){
    return this.http.post(`${environment.patient_registration_prefix_url}/ai/chat`,payload)
  }

}
