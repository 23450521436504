import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IpdRegistrationService {

  private redIds = new BehaviorSubject<any>('');
  getredIds = this.redIds.asObservable();

  constructor(private http: HttpClient) { }

  setRefIds(id):void {
    this.redIds.next(id);
  }

  getPsychiatristlist() {
    return this.http.get(`${environment.admin_prefix_url}/user/getUsersByRole?role_name=Psychiatrist`)
  }
  getPsychologistlist() {
    return this.http.get(`${environment.admin_prefix_url}/user/getUsersByRole?role_name=Psychologist`)
  }

  getPackagelist(package_diagnosis_id?) {
    if(package_diagnosis_id){
      return this.http.get(`${environment.admin_prefix_url}/packagemaster/getAllPackages?package_diagnosis_id=${package_diagnosis_id}`)
    } else {
      return this.http.get(`${environment.admin_prefix_url}/packagemaster/getAllPackages`)
    }
    
  }

  addRPPPatientRegistration(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/rpp/register`, payload);
  }

  rppPatientRegistration(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/rpp-registration`, payload);
  }

  assessmentRegistration(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/assessment-registration`, payload);
  }

  oisRegistration(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/patient/ois-registration`, payload);
  }

  getOldPatientsData() {
    return this.http.get(`${environment.admin_prefix_url}/getPatientPreviousAssigned`)
  }

  updatPatientRPPAssignmentDetails(payload){
    return this.http.post(`${environment.patient_registration_prefix_url}/rpp/updateRppAssignment`,payload)
  }

  addCRT(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/rpp/crtdashbroadassigned`, payload);
  }

  getCrtActivityList(payload) {
    return this.http.post(`${environment.patient_registration_prefix_url}/rpp/getPatientCrtGrids`, payload)
  }

  getRppValidation(rppId: string) {
    return this.http.get(`${environment.patient_registration_prefix_url}/patient/rpp-validation/${rppId}`)
  }

}
