
const BASE_URL = 'https://dev2-api-lb.emoneeds.com';
const DIRECT_BASE_URL = 'https://dev2-api-lb.emoneeds.com';
const API_ADMIN_PORT = ``;
const API_AUTH_PORT = ``;
const API_USER_PORT = ``;
const API_REGISTRATION_PORT = ``;
const API_APPOINTMENT_PORT = ``;
const API_SESSION_PORT = ``;
const API_REPORTS_PORT = ``;
const API_CRM_PORT = ``;
const API_WEBSOCKET_PORT = ``;
const API_ASSESSMENT_PORT = ''

 
export const environment = {
  production: false,
  defaultLanguage: 'en',
  supportedLanguages: [
    { id: 'en', name: 'English', regional: 'English' },
  ],
  admin_prefix_url_port: `${DIRECT_BASE_URL}${API_ADMIN_PORT}`,
  socket_admin_prefix_url_port: `${BASE_URL}:9001`,
  session_prefix_url_port: `${BASE_URL}:9005`,
  admin_prefix_url: `${BASE_URL}${API_ADMIN_PORT}/api/v1/admin`,
  admin_prefix_url_v2: `${BASE_URL}${API_ADMIN_PORT}/api/v2/admin`,
  crm_prefix_url: `${DIRECT_BASE_URL}${API_CRM_PORT}/api/v1/crm`,
  crm_prefix_direct_url: `${BASE_URL}${API_CRM_PORT}/api/v1/crm`,
  patient_registration_prefix_url: `${BASE_URL}${API_REGISTRATION_PORT}/api/v1/registration`,
  patient_assessment_prefix_url: `${BASE_URL}${API_ASSESSMENT_PORT}/api/v1/assessment/patientAssessment/`,
  appointment_url : `${BASE_URL}${API_APPOINTMENT_PORT}/api/v1/appointment`,
  admin_prefix_url_v2_direct: `${DIRECT_BASE_URL}${API_ADMIN_PORT}/api/v2/admin`,
  auth_prefix_url: `${BASE_URL}${API_AUTH_PORT}/api/v1/auth`,
  user_prefix_url: `${BASE_URL}${API_USER_PORT}/api/v1/user`,
  session_prefix_url: `${BASE_URL}${API_SESSION_PORT}/api/v1/session`,
  beneficiary_registration_prefix_url: `${BASE_URL}${API_REGISTRATION_PORT}/api/v1/registration`,
  admin_prefix_direct_url: `${DIRECT_BASE_URL}${API_ADMIN_PORT}/api/v1/admin`,
  session_prefix_direct_url: `${DIRECT_BASE_URL}${API_SESSION_PORT}/api/v1/session`,
  recaptcha_site_key: '6LdNBNUZAAAAAAYITU9accPJNhMSzHMsuqc99RPE',
  report_prefix_direct_url: `${BASE_URL}${API_REPORTS_PORT}/api/v1/reports`,
  report_prefix_url: `${DIRECT_BASE_URL}${API_REPORTS_PORT}/api/v1/reports`,
  finance_prefix_url: `${DIRECT_BASE_URL}${API_REPORTS_PORT}/api/v1/finance`,
  websocket_prefix_direct_url: `${BASE_URL}${API_WEBSOCKET_PORT}`,
  safevac_oauth_sso_uri: `https://safevac.nhp.gov.in/service/uaa/oauth/authorize?response_type=code&state=&client_id=cowin_dio&scope=&redirect_uri=${BASE_URL}/beneficiarymanagement`,
  redirect_uri: `${BASE_URL}/beneficiarymanagement`,
  isUat: false,
  chatboturl: 'https://covid19-staging.nhp.gov.in:80/admin/',
  env: 'OtherEnv',
  google_map_api_key: '',


  //Support Grievance
  registration_url_v2_cdn: `${BASE_URL}${API_REGISTRATION_PORT}/api/v2/registration`,
  local_url: 'http://localhost:4200',
  REPEAT_PER_DAY: {
    1: ['7:00'],
    2: ['7:00','21:00'],
    3: ['7:00','12:00','21:00'],
    4: ['7:00','12:00','16:00','21:00'],
    5: ['7:00','12:00','16:00','18:00','21:00']
  },
  ROLES: {
    HOSPITAL_ADMINISTRATOR: 'Hospital Administrator',
    ORGANIZATION_ADMINISTARTOR: 'Organization Administrator',
    PSYCHIATRIST: 'Psychiatrist',
    DOCTOR: 'Doctor',
    PSYCHOLOGIST: 'Psychologist',
    RECEPTIONIST: 'Receptionist',
    RPP_COUNSELOR: 'RPP Counsellor',
    CRM_COUNSELOR: 'CRM Counsellor',
    ACCOUNT_MANAGER: 'Account Manager',
    DATA_ANALYST: 'Data Analyst'
  }
};

