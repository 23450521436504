import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import { RoleGuard } from './guards/role.guard';

import { TermsComponent } from './screens/terms/terms.component';
import { UnAuthorizeComponent } from './screens/un-authorize/un-authorize.component';
import { NotFoundComponent } from './screens/not-found/not-found.component';
import { ForbiddenComponent } from './screens/forbidden/forbidden.component';
import { UnderConstructionComponent } from './screens/under-construction/under-construction.component';


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full'},
  {
    path: 'login',
    loadChildren: () => import('./screens/auth/auth.module').then(m => m.AuthModule),
    canActivate: [AuthGuard]
  },
  {
    path:'feedback',
    loadChildren: () => import('./screens/patient-feedback/patient-feedback.module')
    .then(m => m.PatientFeedbackModule)
  },
  {
    path:'consent',
    loadChildren: () => import('./screens/patient-consent/patient-consent.module')
    .then(m => m.PatientConsentModule)
  },
  { path: 'terms', component: TermsComponent, pathMatch: 'full' },
  {
    path: 'usermanagement',
    loadChildren: () => import('./screens/user-management/user-management.module').
    then(m => m.UserManagementModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { permission: 'User Management' }
  },
  {
    path: 'rolemanagement',
    loadChildren: () => import('./screens/role-management/role-management.module').
    then(m => m.RoleManagementModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { permission: 'Role Management' }
  },
  {
    path: 'orgmanagement',
    loadChildren: () => import('./screens/org-management/org-management.module').
    then(m => m.OrgManagementModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { permission: 'Organization Management' }
  },
  {

    path: 'passwordpolicy',
    loadChildren: () => import('./screens/password-policy/password-policy.module').
    then(m => m.PasswordPolicyModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { permission: 'Password Policy' }
  },
  {
    path: 'hospitalmanagement',
    loadChildren: () => import('./screens/hospital-management/hospital-management.module').
    then(m => m.HospitalManagementModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { permission: 'Hospital Management' }
  },
  {
    path: 'hospitalconfiguration',
    loadChildren: () => import('./screens/hospital-configuration/hospital-configuration.module').
    then(m => m.HospitalConfigurationModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { permission: 'Hospital Configuration' }
  },
  {
    path: 'opdconfiguration',
    loadChildren: () => import('./screens/opd-configuration/opd-configuration.module').
    then(m => m.OpdConfigurationModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { permission: 'OPD Configuration' }
  },
  {
    path: 'patientregistration',
    loadChildren: () => import('./screens/patient-registration/patient-registration.module').
    then(m => m.PatientRegistrationModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { permission: 'OPD Patient List' }
  },
  {
    path: 'patient-basic-registration',
    loadChildren: () => import('./screens/hospital-basic-registration/hospital-basic-registration.module').
    then(m => m.HospitalBasicRegistrationModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { permission: 'OPD Patient Registration' }
  },
  {
    path: 'ipd-registration',
    loadChildren: () => import('./screens/ipd-registration/ipd-registration.module').
    then(m => m.IpdRegistrationModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { permission: 'RPP Patient Registration' }
  },
  {
    path: 'registration',
    loadChildren: () => import('./screens/registration/registration.module').
    then(m => m.RegistrationModule),
    canActivate: [AuthGuard, RoleGuard]
  },
  {
    path: 'rpp-assignment',
    loadChildren: () => import('./screens/rpp-patient-list/rpp-patient.module').
    then(m => m.RppPatientModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { permission: 'RPP Patient Assignment' }
  },
  {
    path: 'rpp-patient-list',
    loadChildren: () => import('./screens/rpp-patient-allocate/rpp-patient-allocate.module').
    then(m => m.RppPatientAllocateModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { permission: 'RPP Patient List' }
  },
  {
    path: 'assessment-patient-list',
    loadChildren: () => import('./screens/assessment/assessment.module').
    then(m => m.AssessmentModule),
    // canActivate: [AuthGuard, RoleGuard],
    // data: { permission: 'RPP Patient List' }
  },
  {
    path: 'ois-patient-list',
    loadChildren: () => import('./screens/ois-registration/ois-registration.module').
    then(m => m.OisRegistrationModule),
    // canActivate: [AuthGuard, RoleGuard],
    // data: { permission: 'RPP Patient List' }
  },
  {
    path: 'crt-activity',
    loadChildren: () => import('./screens/crt-activity/crt-activity.module').
    then(m => m.CrtActivityModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { permission: 'RPP Patient List' }
  },
  {
    path: 'rpp-renew-patient-list',
    loadChildren: () => import('./screens/rpp-renew-list/rpp-renew-list.module').
    then(m => m.RppRenewListModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { permission: 'RPP Renew Patient List' }
  },
  {
    path: 'rpp-revival-patient-list',
    loadChildren: () => import('./screens/rpp-revival-patient-list/rpp-revival-patient-list.module').
    then(m => m.RppRevivalPatientListModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { permission: 'RPP Revival Patient List' }
  },
  {
    path: 'rpp-list',
    loadChildren: () => import('./screens/rpp-view-list/rpp-view-list.module').
    then(m => m.RppViewListModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { permission: 'RPP List' }
  },  
  {
    path: 'ai-suggestions',
    loadChildren: () => import('./screens/suggestions/suggestions.module').
    then(m => m.SuggestionsModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { permission: 'Suggestions' }
  },
  {
    path: 'team-hierarchy',
    loadChildren: () => import('./screens/team-hierarchy/team-hierarchy.module').
    then(m => m.TeamHierarchyModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { permission: 'Team' }
  },  
  {
    path: 'doctor-roster',
    loadChildren: () => import('./screens/doctor-roster/doctor-roster.module').
    then(m => m.DoctorRosterModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { permission: 'Doctor Roster' }
  },
  {
    path: 'roster',
    loadChildren: () => import('./screens/doctor-roster/doctor-roster.module').
    then(m => m.DoctorRosterModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { permission: 'Doctor Roster' }
  },
  {
    path: 'activity-session',
    loadChildren: () => import('./screens/activity-sessions/activity-sessions.module').
    then(m => m.ActivitySessionsModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { permission: 'Activity Session' }
  },
  {
    path: 'leadmanagement',
    loadChildren: () => import('./screens/lead-management/lead-management.module').
    then(m => m.LeadManagementModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { permission: 'Patient Engagement' }
  },
  {
    path: 'templatemanagement',
    loadChildren: () => import('./screens/template-management/template-management.module').
    then(m => m.TemplateManagementModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { permission: 'Template Management' }
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./screens/dashboard/dashboard.module').
    then(m => m.DashboardModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { permission: 'Dashboard' }
  },
  {
    path: 'meeting',
    loadChildren: () => import('./screens/meeting/meeting.module').
    then(m => m.MeetingModule),
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'early-warning',
    loadChildren: () => import('./screens/early-warning/early-warning.mudule').
    then(m => m.EarlyWarningModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { permission: 'Early Warning' }
  },
  {
    path: 'report',
    loadChildren: () => import('./screens/report/report.module').
    then(m => m.ReportModule),
    //canActivate: [AuthGuard, RoleGuard],
    data: { permission: 'Report' }
  },
  {
    path: 'request',
    loadChildren: () => import('./screens/request/request.module').
    then(m => m.RequestModule),
    //canActivate: [AuthGuard, RoleGuard],
    data: { permission: 'Request' }
  },
  {
    path: 'billing',
    loadChildren: () => import('./screens/billing/billing.module').
    then(m => m.BillingModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { permission: 'Billing' }
  },
  {
    path: 'servicemaster',
    loadChildren: () => import('./screens/service-master/service-master.module').
    then(m => m.ServiceMasterModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { permission: 'Service Master' }
  },
  {
    path: 'packagemaster',
    loadChildren: () => import('./screens/package-master/package-master.module').
    then(m => m.PackageMasterModule),
    canActivate: [AuthGuard, RoleGuard],
    data: { permission: 'Package Master' }
  },
  {
    path: 'session-scheduler',
    loadChildren: () => import('./screens/session-scheduler/session-scheduler.module').
    then(m => m.SessionSchedulerModule),
    // canActivate: [AuthGuard, RoleGuard],
    // data: { permission: 'Session Scheduler' }
  },
  {
    path: 'patient-dashboard',
    loadChildren: () => import('./screens/patient-dashboard/patient-dashboard.module').
    then(m => m.PatientDashboardModule),
    // canActivate: [AuthGuard, RoleGuard],
    // data: { permission: 'Package Master' }
  },
  {
    path: 'wearables',
    loadChildren: () => import('./screens/wearables/wearables.module').
    then(m => m.WearablesModule),
  },
  {
    path: 'support-ticket',
    loadChildren: () => import('./screens/support/support.module').
    then(m => m.SupportModule)
  },
  {
    path: 'crm-management',
    loadChildren: () => import('./screens/crm-management/crm-management.module').
    then(m => m.CrmManagementModule)
  },
  {
    path: 'patient-appointment',
    loadChildren: () => import('./screens/patient-appointment/patient-appointment.module').
    then(m => m.PatientAppointmentModule),
    //canActivate: [AuthGuard, RoleGuard],
    data: { permission: 'Patient Appointment' }
  },
  {
    path: 'timeline',
    loadChildren: () => import('./screens/timeline/timeline.module').
    then(m => m.PatientTimelineModule)
  },
  {
    path: 'lead-source-management',
    loadChildren: () => import('./screens/lead-source-management/lead-source-management.module').
    then(m => m.LeadSourceManagementModule)
  },
  { path: 'unauthorized', component: UnAuthorizeComponent },
  { path: 'notfound', component: NotFoundComponent },
  { path: 'under-construction', component: UnderConstructionComponent },
  { path: 'forbidden', component: ForbiddenComponent },
  { path: '**', redirectTo: 'notfound'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
